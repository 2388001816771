<template>
  <div class="alarm-light" :class="{ active: isActive }">
    <div class="light-core"></div>
    <div class="pulse-ring"></div>
    <div class="glow-effect"></div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

const isActive = ref(true)
</script>

<style scoped>
.alarm-light {
  position: relative;
  width: 18px;
  height: 18px;
  transform: scale(0.9);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.alarm-light.active {
  transform: scale(1);
}

.light-core {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, #e5a147, #846031);
  border-radius: 50%;
  box-shadow: 0 0 20px #e5a147;
  opacity: 0.3;
  transition: opacity 0.3s;
}

.pulse-ring {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  border-radius: 50%;
  opacity: 0;
}

.glow-effect {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 50% 50%, rgba(255, 68, 68, 0.4), rgba(255, 68, 68, 0));
  border-radius: 50%;
  filter: blur(10px);
  opacity: 0;
}

/* 呼吸动画 */
@keyframes breathe {
  0% {
    transform: scale(0.9);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.3;
  }
}

/* 脉冲动画 */
@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

/* 光晕动画 */
@keyframes glow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.8;
  }
  100% {
    opacity: 0;
  }
}

.active .light-core {
  animation: breathe 1.5s infinite ease-in-out;
}

.active .pulse-ring {
  animation: pulse 1.5s infinite ease-out;
}

.active .glow-effect {
  animation: glow 1.5s infinite ease-in-out;
}
</style>
