<!-- 操作日志页面 -->
<template>
  <div :class="isDialog ? 'site-management-container' : 'site-management-container dark-table'" style="height: calc(100% - 0px)">
    <div v-show="!isDialog" style="width: 100%; display: flex; justify-content: flex-end; align-items: center; margin-bottom: 5px">
      <span>站点：</span>
      <el-select v-model="params.station_id" placeholder="请选择站点" clearable style="min-width: 200px; margin-right: 20px">
        <el-option v-for="item in siteOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <span>日期：</span>
      <el-date-picker
        style="max-width: 360px"
        v-model="defaultDateStr"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        format="YYYY-MM-DD HH:mm:ss"
        :clearable="false"
        @change="handleDatePicker"
      />
      <span style="margin-left: 20px">类型：</span>
      <el-select
        v-model="type"
        placeholder="请选择"
        clearable
        multiple
        filterable
        allow-create
        collapse-tags
        collapse-tags-tooltip
        :max-collapse-tags="3"
        style="min-width: 200px; margin-right: 20px"
        @change="handleChange"
      >
        <el-option v-for="item in operationTypeOptions" :key="item.value" :label="item.label" :value="item.value" />
      </el-select>
      <el-button type="primary" @click="getAlarmList()" style="width: 80px">查询</el-button>
    </div>

    <TableRender
      ref="dataTableRef"
      height="100%"
      :class="isDialog ? 'TableRenderBox' : ''"
      :loading="tableLoading"
      :table-data="tableData"
      :table-header="colomns"
      :is-show-index="true"
    />

    <Dialog title="查看操作日志详情" width="800px" v-model:visible="visible">
      <div style="height: 100%">
        <JsonEditor style="height: 100%" ref="jsoneditor3" v-model="jsonData" :modeList="['view']" currentMode="view" />
      </div>
      <div v-if="lineBreakData && lineBreakData.includes('\n')" style="margin-top: 15px">
        <label>能量流：</label>
        <el-input v-model="lineBreakData" type="textarea" autosize disabled />
      </div>
      <template #footer>
        <el-button @click="visible = false">取消</el-button>
      </template>
    </Dialog>
  </div>
</template>

<script setup>
import { nextTick, onUnmounted, onMounted, reactive, ref, computed } from 'vue'
import { getSites, getOperationTypeApi } from '@/server/site'
import useStorage from '@/hooks/storage'
import { format } from 'date-fns'
import { ElMessage } from 'element-plus'
import TableRender from '@/components/table'
import { getOperationLog } from '@/server/operate'
import { ElButton } from 'element-plus'
import Dialog from '@/components/dialog'
import JsonEditor from '@/components/editor/json'

const props = defineProps({
  isDialog: {
    type: Boolean,
    default: false,
  },
})
const emits = defineEmits('failArr')

const { getStorage, setStorage } = useStorage()
const tableData = ref([])
const dataTableRef = ref()
const tableLoading = ref(false)
const visible = ref(false)
const colomns = ref([
  {
    label: '站点名称',
    prop: 'station_name',
  },
  {
    label: '时间',
    prop: 'time',
  },
  {
    label: '操作类型',
    prop: 'type',
  },
  {
    label: '设备名称',
    prop: 'alias',
  },
  {
    label: '状态',
    prop: 'state',
  },
  {
    label: '日志详情',
    prop: 'content',
    // tooltip: true,
    render: (h, data) => {
      return h(
        'div',
        {
          style: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          },
        },
        [JSON.stringify(data.row.content)],
      )
    },
  },
  {
    label: '操作',
    minWidth: 50,
    render: (h, data) => {
      const child = [
        h(
          ElButton,
          {
            link: true,
            type: 'primary',
            onClick: () => {
              showCannelDetail(data.row)
            },
          },
          () => '查看',
        ),
      ]
      return h('span', {}, child)
    },
  },
])
const defaultDateStr = ref([`${format(new Date(), 'yyyy-MM-dd')} 00:00:00`, `${format(new Date(), 'yyyy-MM-dd')} 23:59:59`])
const type = ref([])
// 表格数据查询入参
const params = ref({
  station_id: '',
  start: defaultDateStr.value[0],
  end: defaultDateStr.value[1],
  operation_type: type.value.join("','"),
  limit: 50,
  offset: 0,
  exculde_operation_type: "策略快照','cop",
})
let setIntervalId = null

onMounted(async () => {
  await getSiteListData()
  await getOperationType()
  await getAlarmList()
  if (props.isDialog) {
    setIntervalId = window.setInterval(async () => {
      await getAlarmList()
    }, 1000 * 60)
  }
  const container = dataTableRef.value?.$el.querySelector('.el-scrollbar__wrap')
  if (container) {
    container.addEventListener('scroll', handleScroll)
  }
})

onUnmounted(() => {
  const container = dataTableRef.value?.$el.querySelector('.el-scrollbar__wrap')
  if (container) {
    container.removeEventListener('scroll', handleScroll)
  }
  if (props.isDialog) {
    window.clearInterval(setIntervalId)
    setIntervalId = null
  }
})

// 获取数据列表
async function getAlarmList(val) {
  tableLoading.value = true
  try {
    if (tableData.value.length === 0) {
      params.value.offset = 0
    }
    if (props.isDialog) {
      params.value.station_id = getStorage('activeSiteId')
      params.value.start = format(new Date(new Date().getTime() - 5 * 60 * 1000), 'yyyy-MM-dd HH:mm:ss')
      params.value.end = format(new Date(), 'yyyy-MM-dd HH:mm:ss')

      // params.value.start = '2025-01-01 00:00:00'
      // params.value.end = '2025-02-28 00:00:00'
    }

    const { data } = await getOperationLog(params.value)
    if (data.code === 200) {
      let arr = []
      arr = data.msg.map(i => {
        return {
          time: i.create_time,
          type: i.operation_type,
          alias: i.device_name,
          content: i.raw,
          station_name: i.station_name,
          state: i.state,
          id: i.id,
        }
      })
      if (val) {
        tableData.value = tableData.value.concat(arr)
      } else {
        tableData.value = arr
      }
      if (props.isDialog) {
        // console.log('tableData', tableData.value)
        let failArr = tableData.value.filter(i => i.state === '失败')
        emits('failArr', failArr)
      }
    } else {
      ElMessage.warning('数据获取失败')
    }
  } catch (error) {
    console.log(error)
    tableLoading.value = false
  }
  tableLoading.value = false
}
// 监听列表滚动 触底加载事件
function handleScroll() {
  const container = dataTableRef.value?.$el.querySelector('.el-scrollbar__wrap')
  if (container) {
    if (tableLoading.value) return
    const { scrollTop, scrollHeight, clientHeight } = container
    if (scrollTop + clientHeight >= scrollHeight) {
      console.log('滚动到底部了')
      if (tableData.value.length === params.value.offset + params.value.limit) {
        params.value.offset = params.value.offset + params.value.limit
        getAlarmList('isBottom')
      }
    }
  }
}
// 时间筛选
function handleDatePicker(date) {
  defaultDateStr.value = [format(date[0], 'yyyy-MM-dd HH:mm:ss'), format(date[1], 'yyyy-MM-dd HH:mm:ss')]
  if (new Date(defaultDateStr.value[1]).getTime() - 1000 * 60 * 60 * 24 * 1 > new Date(defaultDateStr.value[0]).getTime()) {
    defaultDateStr.value = [`${format(new Date(), 'yyyy-MM-dd')} HH:mm:ss`, `${format(new Date(), 'yyyy-MM-dd')} HH:mm:ss`]
    return ElMessage.error('查询时间不能超过 1 天')
  }
  params.value.start = defaultDateStr.value[0]
  params.value.end = defaultDateStr.value[1]
  // getAlarmList()
}
// 类型筛选
function handleChange(val) {
  params.value.operation_type = val.join("','")
  // getAlarmList()
}

// 站点相关
const siteOptions = ref([])
// 获取站点列表
async function getSiteListData() {
  try {
    let userInfo = getStorage('userInfo')
    userInfo = userInfo.user_info
    const res = await getSites({
      include_hide: 0,
      userid: userInfo.user_id,
      roleids: userInfo.roleids,
    })
    if (res.data && res.data.msg) {
      siteOptions.value = res.data.msg.map(item => {
        return {
          label: item.item_label,
          value: item.id,
        }
      })
      // params.value.station_id = getStorage('activeSiteId')
    }
  } catch (error) {
    console.log(error)
  }
}

// 日志类型相关
const operationTypeOptions = ref([
  { label: '策略', value: 'strategy' },
  { label: '集群', value: 'cluster' },
  { label: '模拟', value: 'simulator' },
  { label: '自检', value: 'check' },
  { label: '检查', value: '检查' },
  { label: '运行', value: '运行' },
])
// 获取日志类型
async function getOperationType() {
  try {
    const { data } = await getOperationTypeApi()
    if (data.code === 200) {
      let arr = []
      arr = data.msg.map(i => {
        return {
          label: i,
          value: i,
        }
      })
      operationTypeOptions.value = operationTypeOptions.value.concat(arr)
    }
  } catch (error) {
    console.log(error)
  }
}

const jsonData = ref('')
const lineBreakData = ref('')
// 点击查看日志详情
function showCannelDetail(val) {
  // console.log("🚀 - val -->", val)
  jsonData.value = JSON.stringify(val.content)
  lineBreakData.value = val.content['能量流']
  visible.value = true
}

defineExpose({
  showCannelDetail,
  getAlarmList,
})
</script>

<style lang="scss" scoped>
.table-render {
  width: 100%;
  height: calc(100% - 38px);
}
:deep(.TableRenderBox) {
  height: calc(100% - 0px);
}
:deep(.el-table) {
  width: 100%;
  height: calc(100% - 0px) !important;
  .el-table__inner-wrapper {
    border: 1px solid #7c797e !important;
    width: 100%;
    height: 100% !important;
  }
}
</style>
