<template>
  <div class="operationLogBox">
    <AlarmBreathingLight v-show="isShowLight" @click.stop="handleAlarmLight" />

    <div class="show-btn" title="操作日志列表" @click="handleShowLogTools">
      <el-icon color="#fff"><Tickets /></el-icon>
    </div>

    <!-- 操作日志弹窗 -->
    <div v-show="showDialog">
      <el-dialog v-model="showLogTools" title="操作日志" width="70%" @close="showDialog = false">
        <div style="width: 100%; height: 54vh">
          <OperationLog ref="operationLogRef" isDialog @failArr="handleFailArr" />
        </div>
        <template #footer>
          <el-button @click="showDialog = false">取消</el-button>
        </template>
      </el-dialog>
    </div>
  </div>

  <!-- 告警列表浮窗 -->
  <div ref="alarmAlertBoxRef" class="alarmAlertBox" v-show="isAlarmAlert">
    <el-icon @click="isAlarmAlert = false"><Close /></el-icon>
    <div class="alarmAlertBoxdiv scrollbar">
      <el-alert v-for="item in alarmAlertArr" :key="item.id" type="warning" show-icon @close="handleClose(item)" @click="handleClick(item)">
        <template #title>
          <span>{{ item.alias }}</span>
        </template>
        <template #default>
          <span>{{ item.time }} {{ item.type }} {{ item.state }}</span>
        </template>
      </el-alert>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick, onMounted, onUnmounted, watch } from 'vue'
import { Tickets, Close } from '@element-plus/icons-vue'
import AlarmBreathingLight from './AlarmBreathingLight.vue'
import OperationLog from '../../views/system/operationLog.vue'
import { useStore } from 'vuex'
import useStorage from '@/hooks/storage'
const { getStorage, setStorage } = useStorage()

const store = useStore()
const isShowLight = ref(false)
const showLogTools = ref(true)
const showDialog = ref(false)
const isAlarmAlert = ref(false)
const alarmAlertArr = ref([])

// 点击操作日志列表
function handleShowLogTools() {
  showDialog.value = true
  showLogTools.value = true
}
// 如果有失败的日志，则显示呼吸灯（近5分钟的失败日志）
function handleFailArr(val) {
  // console.log(val)
  if (val.length > 0) {
    const siteAlarmCacheMap = getStorage('siteAlarmCacheMap')
    if (!siteAlarmCacheMap) {
      setStorage('siteAlarmCacheMap', { [getStorage('activeSiteId')]: [] })
    }
    const curSiteAlarmCacheArr = siteAlarmCacheMap[getStorage('activeSiteId')]
    alarmAlertArr.value = val.filter(i => !curSiteAlarmCacheArr.includes(i.id))
    // 如果本地缓存内的数据不在近5分钟内上报的失败日志内，则在缓存中清除
    if (curSiteAlarmCacheArr.length > 0) {
      const newCacheArr = curSiteAlarmCacheArr.filter(i => val.map(j => j.id).includes(i))
      setStorage('siteAlarmCacheMap', { ...siteAlarmCacheMap, [getStorage('activeSiteId')]: newCacheArr })
    }
    isShowLight.value = true
  } else {
    // 近5分钟内没有上报失败的日志，则关闭呼吸灯，清除缓存
    isShowLight.value = false
    const siteAlarmCacheMap = getStorage('siteAlarmCacheMap')
    if (siteAlarmCacheMap && siteAlarmCacheMap[getStorage('activeSiteId')] && siteAlarmCacheMap[getStorage('activeSiteId')].length > 0) {
      setStorage('siteAlarmCacheMap', { ...siteAlarmCacheMap, [getStorage('activeSiteId')]: [] })
    }
  }
}
// 点击告警呼吸灯
function handleAlarmLight() {
  isAlarmAlert.value = true
}
// 点击关闭告警时，视为已读改条告警，在下次告警上报时，从alarmAlertArr中过滤掉已读告警
function handleClose(val) {
  const siteAlarmCacheMap = getStorage('siteAlarmCacheMap')
  const curSiteAlarmCacheArr = siteAlarmCacheMap[getStorage('activeSiteId')]
  curSiteAlarmCacheArr.push(val.id)
  setStorage('siteAlarmCacheMap', { ...siteAlarmCacheMap, [getStorage('activeSiteId')]: curSiteAlarmCacheArr })
}
const operationLogRef = ref()
// 点击告警时，显示详情弹窗
function handleClick(val) {
  // console.log(operationLogRef.value.showCannelDetail)
  // operationLogRef.value.showCannelDetail(val)
}

const alarmAlertBoxRef = ref(null)
const handleClickOutside = event => {
  if (alarmAlertBoxRef.value && !alarmAlertBoxRef.value.contains(event.target) && event.target.id !== 'trigger-button') {
    isAlarmAlert.value = false
  }
}
onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})
onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})

// 监听当站点切换时，去重新获取日志信息
watch(
  () => store.getters.getActiveSiteId,
  (newValue, oldValue) => {
    operationLogRef.value.getAlarmList()
  },
)
</script>

<style lang="scss" scoped>
.operationLogBox {
  // border: red solid 1px;
  position: fixed;
  top: 75px;
  right: 80px;
  z-index: 100;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .show-btn {
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    text-align: center;
    width: 36px;
    height: 30px;
    line-height: 36px;
    cursor: pointer;
    margin-left: 10px;

    .el-icon {
      font-size: 18px;
      box-sizing: border-box;

      & svg {
        font-size: 18px;
      }
    }
  }

  :deep(.el-dialog) {
    background-color: #000;

    .el-dialog__header {
      & .el-dialog__title,
      .el-dialog__close {
        color: #fff;
      }
    }
    .el-table tr {
      background-color: #000;
      color: #fff;
    }
    .el-table__header {
      & th {
        background-color: #000;
      }
    }
    .el-table__header-wrapper,
    .el-table__body-wrapper {
      background-color: #000;
    }
    .el-table__row:hover {
      background-color: #334569 !important;
    }
    .el-table--enable-row-hover .el-table__body tr:hover > td.el-table__cell {
      background-color: #334569 !important;
    }
  }
}

.alarmAlertBox {
  // border: red solid 1px;
  position: fixed;
  top: 120px;
  right: 0;
  z-index: 100;
  padding: 10px;
  padding-top: 20px;
  box-sizing: border-box;
  background-color: #000;
  width: 20vw;
  height: 50vh;
  border-radius: 5px;

  .alarmAlertBoxdiv {
    // border: red solid 1px;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 10px;
  }

  .el-icon {
    // border: red solid 1px;
    position: absolute;
    top: 0px;
    right: 0px;
    font-size: 30px;
    cursor: pointer;
    color: #fff;
  }

  .el-alert {
    margin-bottom: 10px;
    cursor: pointer;

    & span {
      font-size: 14px;
    }
  }
  .el-alert:last-child {
    margin-bottom: 0px;
  }
}
</style>
