// 系统管理
export default {
  path: '/System',
  component: () => import('views/Layout.vue'),
  children: [
    {
      path: 'SiteManager',
      name: 'SiteManager',
      component: () => import(/*webpackChunkName: "SiteManager"*/ 'views/system/siteManager'),
      meta: {
        _meauname: '站点管理',
        keepAlive: true,
      },
    },

    {
      path: 'AccountInManagement',
      name: 'AccountInManagement',
      component: () => import(/*webpackChunkName: "AccountInManagement"*/ 'views/system/accountInManagement'),
      meta: {
        _meauname: '账户管理',
        keepAlive: true,
      },
    },
    {
      path: 'RoleManagement',
      name: 'RoleManagement',
      component: () => import(/*webpackChunkName: "RoleManagement"*/ 'views/system/roleManagement'),
      meta: {
        _meauname: '角色管理',
        keepAlive: true,
      },
    },
    {
      path: 'OperationLog',
      name: 'OperationLog',
      component: () => import(/*webpackChunkName: "OperationLog"*/ 'views/system/operationLog'),
      meta: {
        _meauname: '操作日志',
        keepAlive: true,
      },
    },

    // {
    //   path: 'LogManagement',
    //   name: 'LogManagement',
    //   component: () => import(/*webpackChunkName: "LogManagement"*/ 'views/system/logs'),
    //   meta: {
    //     _meauname: '日志管理',
    //     keepAlive: true,
    //   },
    // },
    // {
    //   path: 'AccountManagement',
    //   name: 'AccountManagement',
    //   component: () =>
    //     import(
    //       /*webpackChunkName: "AccountManagement"*/ 'views/system/account'
    //     ),
    //   meta: {
    //     _meauname: '账号管理',
    //     keepAlive: true,
    //   },
    // },
    // {
    //   path: 'RoleManagement',
    //   name: 'RoleManagement',
    //   component: () =>
    //     import(/*webpackChunkName: "roles"*/ 'views/system/roles'),
    //   meta: {
    //     _meauname: '角色管理',
    //     keepAlive: true,
    //   },
    // },
    {
      path: 'PersonalityConfiguration',
      name: 'PersonalityConfiguration',
      component: () => import(/*webpackChunkName: "personalConfig"*/ 'views/system/personalConfig'),
      meta: {
        _meauname: '个性配置',
        keepAlive: true,
      },
    },
    {
      path: 'GatewayManager',
      name: 'GatewayManager',
      component: () => import(/*webpackChunkName: "GatewayManager"*/ 'views/system/gatewayManager'),
      meta: {
        _meauname: '网关管理',
        keepAlive: true,
      },
    },
    {
      path: 'ClientReleaseManager',
      name: 'ClientReleaseManager',
      component: () => import(/*webpackChunkName: "ClientReleaseManager"*/ 'views/system/clientReleaseManager'),
      meta: {
        _meauname: '应用发布管理',
        keepAlive: true,
      },
    },
    {
      path: 'DomainManager',
      name: 'DomainManager',
      component: () => import(/*webpackChunkName: "DomainManager"*/ 'views/system/domainManager'),
      meta: {
        _meauname: '租户管理',
        keepAlive: true,
      },
    },
    {
      path: 'ConfigManager',
      name: 'ConfigManager',
      component: () => import(/*webpackChunkName: "ConfigManager"*/ 'views/system/configManager/index'),
      meta: {
        _meauname: '配置管理',
        keepAlive: true,
      },
    },
    {
      path: 'PolicyBase',
      name: 'PolicyBase',
      component: () => import('views/system/policyBase/index.vue'),
      meta: {
        _meauname: '策略库',
        keepAlive: true,
        // skipPermission: true,
      },
    },
    // {
    //   path: 'ModelBase',
    //   name: 'ModelBase',
    //   component: () => import('views/system/modelBase/index.vue'),
    //   meta: {
    //     _meauname: '物模型库',
    //     keepAlive: true,
    //     skipPermission: true,
    //   },
    // },

    {
      path: 'FlowManager',
      name: 'FlowManager',
      component: () => import('views/system/flowManager/index.vue'),
      meta: {
        _meauname: '流水线管理',
        keepAlive: true,
        // skipPermission: true,
      },
    },

    {
      path: '/:pathMatch(.*)',
      component: () => import('views/404'),
      meta: {
        _meauname: '系统管理404',
      },
    },
  ],
}
