<template>
  <el-table-column
    v-for="(item, key) in tableHeader"
    :key="key"
    :prop="item.prop"
    :label="item.label"
    :fixed="item.fixed"
    :min-width="item.minWidth"
    :width="item.maxWidth"
    :sortable="item.sortable ? item.sortable : false"
    :filters="item.filters"
    :column-key="item.columnKey"
    :filtered-value="item.filteredValue"
    :filter-multiple="item.filterMultiple"
    :align="item.align"
    :show-overflow-tooltip="item.tooltip"
    :render-header="item.renderHeader"
    :type="item.columnType"
  >
    <template #header="scope">
      <ex-slot v-if="item.customerHeader" :customer-header="item.customerHeader" :row="scope.row" :index="scope.$index" :column="item" />
      <span v-else>{{ item.label || '-' }}</span>
    </template>
    <template #default="scope">
      <template v-if="item.columns">
        <el-table-column v-for="(val, index) in item.columns" :key="index" :label="val.label" :prop="val.prop">
          <template #default="scope1">
            <ex-slot v-if="val.render || val.customer" :render="val.render" :customer="val.customer" :row="scope1.row" :index="scope1.$index" :column="val" />
            <span v-else>{{ getTargetValue(scope1.row, val.prop) || '-' }}</span>
          </template>
        </el-table-column>
      </template>
      <template v-else-if="item.slotName">
        {{ item.slotName }}
        <slot name="operation"></slot>
      </template>
      <ex-slot v-else-if="item.render || item.customer" :render="item.render" :customer="item.customer" :row="scope.row" :index="scope.$index" :column="item" />
      <span v-else>{{ getTargetValue(scope.row, item.prop) || '-' }}</span>
    </template>
  </el-table-column>
</template>

<script>
export default {
  name: 'ColumnItem',
}
</script>

<script setup>
import { h } from 'vue'
const props = defineProps({
  tableHeader: {
    type: Array,
    default: () => [],
  },
})

// 自定义内容的组件
const exSlot = {
  functional: true,
  props: {
    row: Object,
    render: Function,
    customer: Function, // 简化html render customer 均是渲染html模板
    customerHeader: Function,
    index: Number,
    column: {
      type: Object,
      default: null,
    },
  },
  render: props => {
    const params = {
      row: props.row,
      index: props.index,
    }

    if (props.column) params.column = props.column
    if (props.customerHeader) {
      // return h('div', [data.props.customerHeader(params)])
      return props.customerHeader(h, params)
    }
    if (props.customer) {
      return h('div', [props.customer(params)])
    }
    return props.render(h, params)
  },
}

// 获取目标值
const getTargetValue = (row, prop) => {
  if (!prop) {
    return ''
  }
  if (prop.indexOf('.') < 0) {
    return row[prop]
  }
  let propArr = prop.split('.')
  let target = row
  for (let item of propArr) {
    if (!target[item]) {
      return ''
    }
    target = target[item]
  }
  return target
}
</script>

<style scoped lang="scss"></style>
