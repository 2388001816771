<template>
  <div v-loading="loading" element-loading-text="疯狂加载中..." element-loading-background="rgba(255, 255, 255, 0.2)" :class="`table-render ${props.type}`">
    <el-table
      ref="tableRef"
      :border="border"
      :data="tableData"
      :row-class-name="tableRowClassName"
      :cell-class-name="cellClassName"
      :empty-text="emptyText"
      :height="limitHeight ? height || tableHeight : null"
      :show-header="showHeader"
      :highlight-current-row="highlightCurrentRow"
      style="width: 100%"
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
      @select="select"
      @select-all="selectAll"
      @cell-click="cellClick"
      @cell-mouse-enter="cellMouseenter"
      @cell-mouse-leave="cellMouseleave"
    >
      <el-table-column v-if="isExpand" type="expand">
        <template #default="props">
          <slot name="expand" :props="props"></slot>
        </template>
      </el-table-column>
      <el-table-column v-if="isSelect" type="selection" width="55" />
      <el-table-column v-if="showSelect" type="selection" width="55" />
      <el-table-column v-if="isShowIndex" class-name="order-index" label="序号" type="index" align="center" :index="table_index" min-width="50" />
      <Columns :tableHeader="tableHeader"> </Columns>
    </el-table>

    <el-pagination
      v-if="tableData.length && dataCount && +dataCount !== 0"
      :total="dataCount"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      :current-page="pageNum"
      background
      :layout="layout"
      style="margin: 10px 0; float: right"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, watch, ref, h } from 'vue'
import Columns from './column.vue'

const props = defineProps({
  white: {
    type: String,
    default: 'dark',
  },
  propRef: {
    type: String,
    default: '',
  },
  border: {
    type: Boolean,
    default: false,
  },
  // 表格数据
  tableData: {
    type: Array,
    default: () => {
      return []
    },
  },
  showSelect: {
    type: Boolean,
    default: false,
  },
  isShowIndex: {
    type: Boolean,
    default: false,
  },
  isSelect: {
    type: Boolean,
    default: false,
  },
  isExpand: {
    type: Boolean,
    default: false,
  },
  // 表头数据
  tableHeader: {
    type: Array,
    default: () => [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  limitHeight: {
    type: Boolean,
    default: true,
  },
  heights: {
    // 具体的table高度
    type: [String, Number],
    default: '',
  },
  height: {
    type: [String, Number],
    default: '',
  },
  tableRowClassName: {
    type: [Function, String],
    default: '',
  },
  cellClassName: {
    type: [Function, String],
    default: '',
  },
  emptyText: {
    type: String,
    default: '抱歉，暂无数据记录',
  },
  handleSelectionChange: {
    type: Function,
    default: () => {},
  },
  select: {
    type: Function,
    default: () => {},
  },
  selectAll: {
    type: Function,
    default: () => {},
  },
  cellMouseenter: {
    type: Function,
    default: () => {},
  },
  cellMouseleave: {
    type: Function,
    default: () => {},
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  dataCount: Number,
  pageSize: {
    type: Number,
    default: 10,
  },
  pageNum: {
    type: Number,
    default: 1,
  },
  handleCurrentChange: Function,
  handleSizeChange: Function,
  sortChange: {
    type: Function,
    default: () => {},
  },
  pageSizes: {
    type: Array,
    default: () => [10, 20, 30, 40, 50, 100],
  },
  layout: {
    type: String,
    //   default: 'total, sizes, prev, pager, next, jumper',
    default: 'prev, pager, next',
  },
  cutHeight: {
    // 减高度
    type: [String, Number],
    default: 180,
  },
  cellClick: {
    // 单元格单机事件
    type: Function,
    default: () => {},
  },
  highlightCurrentRow: {
    // 默认高亮当前行
    type: Boolean,
    default: false,
  },
})
const tableHeight = ref(window.innerHeight - props.cutHeight)
watch(
  () => props.cutHeight,
  () => getHeight(),
)

onMounted(() => {
  window.addEventListener('resize', getHeight)
})
onUnmounted(() => {
  window.removeEventListener('resize', getHeight)
})

const getHeight = () => {
  tableHeight.value = window.innerHeight - props.cutHeight
}
// 序号
const table_index = index => {
  return (props.pageNum - 1) * props.pageSize + index + 1
}

const emits = defineEmits('sort-events', 'filter-events', 'click-events')
// 排序事件
const handleSort = sort => {
  emits('sort-events', sort)
}
// 筛选事件
const filterHandler = filters => {
  emits('filter-events', filters)
}
// 某一行被点击
const handleRowClick = row => {
  emits('click-events', row)
}

const tableRef = ref()

defineExpose({
  tableRef,
})
</script>

<style scoped lang="scss">
.table-render {
  // background: #fff;
  :deep(.el-table) {
    border: 1px solid #7c797e;

    .el-table__header {
      padding: 5px 0;
      background: rgba(255, 255, 255, 0.15);
      .el-table__cell {
        border: none !important;
      }
    }
    .el-table__cell {
      border-bottom: 1px solid #7c797e !important;
    }
    th {
      .cell {
        line-height: 16px !important;
      }
    }
    .cell {
      .el-button--text {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    .el-table__fixed {
      height: calc(100% - 8px) !important;
    }
  }
  :deep(.el-table--scrollable-x) {
    .el-table__body-wrapper {
      // display: none !important;
      &::-webkit-scrollbar {
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        // background: #e7e8ee;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px #fff;
        border-radius: 10px;
        // background: #ededed;
      }
    }
  }
  :deep(.el-table--scrollable-y) {
    .el-table__body-wrapper {
      // display: none !important;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 10px;
        box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
        // background: #e7e8ee;
      }
      &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow: inset 0 0 5px #fff;
        border-radius: 10px;
        // background: #ededed;
      }
    }
  }
  :deep(.caliber_desc) {
    li {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  :deep(.order-index) {
    .cell {
      padding: 0;
    }
  }
  :deep(.el-pagination) {
    /*margin-top: 20px;*/
    text-align: right;
    padding: 4px 28px 2px 21px;
    .el-pagination__total {
      float: left;
    }
    .number.active {
      // background-color: #ffffff !important;
      border: 1px solid #467afe;
      color: #467afe !important;
      border-radius: 4px;
    }
  }
}
</style>
