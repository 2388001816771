<template>
  <div id="jsonEditor" :style="`width: 100%; height: ${props.height || containerHeight}px`" ref="jsoneditorWrap">
    <JsonEditorVue3
      style="width: 100%; height: 100%"
      ref="editor"
      v-model="jsonData"
      :modeList="props.modeList"
      :currentMode="props.currentMode"
      @change="handleChange"
      @blur="handleBlur"
    />
  </div>
</template>

<script setup>
import JsonEditorVue3 from 'json-editor-vue3'
import { onMounted, ref, watch } from 'vue'
import { isJSON } from '@/utils/utils'

const props = defineProps({
  modelValue: {
    type: String,
  },
  height: Number,
  modeList: {
    type: Array,
    default: () => ['tree', 'code', 'form', 'text', 'view'],
  },
  currentMode: {
    type: String,
    default: 'code',
  },
})

const jsoneditorWrap = ref()
const editor = ref()
const jsonData = ref('')

watch(
  () => props.modelValue,
  val => {
    if (props.modelValue) {
      let val = props.modelValue
      if (isJSON(val)) {
        jsonData.value = JSON.parse(val)
      } else {
        jsonData.value = val
      }
    } else {
      jsonData.value = {}
    }
  },
  { immediate: true },
)

const emit = defineEmits(['update:modelValue', 'change'])

const handleChange = async val => {
  emit('change', JSON.stringify(jsonData.value))
  emit('update:modelValue', JSON.stringify(jsonData.value))
}

const validate = async () => {
  const res = await editor.value.editor.validate()
  return res.length === 0
}

defineExpose({ validate })

const handleBlur = async editor => {
  const res = await editor.validate()
  // res 是错误列表，如果是空数组，则表示检测没有错误
}

const containerHeight = ref(500)
onMounted(() => {
  // const contentDom = document.body
  // containerHeight.value = props.height || contentDom.offsetHeight
})
</script>

<style lang="scss" scoped></style>
